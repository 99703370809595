import * as React from 'react';
import Button from '../Components/Button';
import Typography from '../Components/Typography';
import BannerLayout from '../Layouts/BannerLayout';
import BackgroundImage from '../Images/2.jpg';
import Images from '../Images/index';

const backgroundImage =
  'https://images.unsplash.com/photo-1534854638093-bada1813ca19?auto=format&fit=crop&w=1400';


export default function ProductHero() {
  return (
    <BannerLayout
      sxBackground={{
        backgroundImage: `url(${Images.BannerAcupuncture})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={Images.BannerAcupuncture}
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        Bli av med smärta, värk och stress!
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}
      >
        3 min från tunnelbanan i Kista
      </Typography>
      <Button
        color="warning"
        variant="contained"
        size="large"
        component="a"
        href="/Booking"
        sx={{ minWidth: 200 }}
      >
        Boka Tid
      </Button>
      <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
        Discover the experience
      </Typography>
    </BannerLayout>
  );
}