import ButtonBase from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";

const ImageIconButton = styled(ButtonBase)(({ theme }) => ({
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "40vh",
    [theme.breakpoints.down("md")]: {
      width: "100% !important",
      height: 100,
    },
    "&:hover": {
      zIndex: 1,
    },
    "&:hover .imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover .imageMarked": {
      opacity: 0,
    },
    "&:hover .imageTitle": {
      border: "4px solid currentColor",
    },
    "& .imageTitle": {
      position: "relative",
      padding: `${theme.spacing(2)} ${theme.spacing(4)} 14px`,
    },
    "& .imageMarked": {
      height: 3,
      width: 18,
      background: theme.palette.common.white,
      position: "absolute",
      bottom: -2,
      left: "calc(50% - 9px)",
      transition: theme.transitions.create("opacity"),
    },
  }));

  export default ImageIconButton;