import * as React from "react";
import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Container from "@mui/material/Container";
import Typography from "../Components/Typography";
import ImageIconButton from "../Components/ImageIconButton";
import Images from '../Images/index';

const ImageBackdrop = styled("div")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: "#000",
  opacity: 0.5,
  transition: theme.transitions.create("opacity"),
}));


const images = [
  {
    url: `${Images.BookingAkupunktur}`,
    title: "Kinesisk Akupunktur",
    width: "70%",
    href:"https://www.timecenter.se/kistaakupunktur/boka/time/?rid=6417&tjid=14556&oid=6282847&tidval=60"
  },
  {
    url: `${Images.Konsultation}`,
    title: "Konsultation",
    width: "30%",
    href:"https://www.timecenter.se/kistaakupunktur/boka/time/?rid=6417&tjid=115685&oid=6282848&tidval=20"
  },
  {
    url: `${Images.MassageHomePage}`,
    title: "Kinesisk Kroppsmassage",
    width: "100%",
    href:"https://www.timecenter.se/kistaakupunktur/boka/time/?rid=6417&tjid=135538&oid=6282849&tidval=60"
  },
  // {
  //   url: "https://images.unsplash.com/photo-1476480862126-209bfaa8edc8?auto=format&fit=crop&w=400",
  //   title: "Kinesisk Ryggmassage",
  //   width: "33%",
  //   href:"https://www.youtube.com"
  // },
  // {
  //   url: "https://images.unsplash.com/photo-1453747063559-36695c8771bd?auto=format&fit=crop&w=400",
  //   title: "Nack & Axelmassage",
  //   width: "33%",
  //   href:"https://www.youtube.com"
  // },


];

export default function Services() {
  return (
    <Container component="section" sx={{ mt: 8, mb: 4 }}>
      <Typography variant="h4" marked="center" align="center" component="h2">
        Hämta Sjukvården och Boka Tid
      </Typography>
      <Box sx={{ mt: 6, display: "flex", flexWrap: "wrap" }}>
        {images.map((image) => (
          <ImageIconButton
            key={image.title}
            style={{
              width: image.width,
              height: "40vh"
            }}
          >
            <Link href={image.href} target="_blank">
              <Box
                sx={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundSize: "cover",
                  backgroundPosition: "center 40%",
                  backgroundImage: `url(${image.url})`,
                }}
              />
              <ImageBackdrop className="imageBackdrop" />

              <Box
                sx={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "common.white",
                }}
              >
                <Typography
                  component="h3"
                  variant="h6"
                  color="inherit"
                  className="imageTitle"
                >
                  {image.title}
                  <div className="imageMarked" />
                </Typography>
              </Box>
            </Link>
          </ImageIconButton>
        ))}
      </Box>
    </Container>
  );
}
