import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../Components/Typography';
// import TextField from '../Components/TextField';
import Snackbar from '../Components/SnackBar';
// import Button from '../Components/Button';
import Images from '../Images/index';
import '../CSS/BasicContactInfo.css'

function BasicContactInfo() {
  const [open, setOpen] = React.useState(false);

  // const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container component="section" sx={{ mt: 10, display: 'flex' }}>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ zIndex: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              bgcolor: 'warning.main',
              py: 8,
              px: 3,
            }}
          >
            <Box component="form" sx={{ maxWidth: 400 }}>
              {/* <Typography variant="h4" component="h4" gutterBottom>
                Receive offers
              </Typography> */}
              <Typography variant="body1">
                <span className='paragraph'>Address: </span>Kastrupgatan 1, Kista, Stockholm
              </Typography>
              <Typography variant="body1">
                <span className='paragraph'>Email: </span>kistaakupunktur@gmail.com
              </Typography>
              <Typography variant="body1">
                <span className='paragraph'>Mobil: </span> <span id='spacing'>0723090661</span>
              </Typography>
              <Typography variant="body1">
                <span className='paragraph'>Öppentider: </span>Mån-Fre: 10:00 - 19:00
                            <span id="openPeriod">Lör: 10:00 - 16:00</span>
              </Typography>
              <Typography variant="body1">
                <span className='paragraph'>Med Tunnelbana: </span>Åk från Stockholm mot Akalla, Sig av vid Kista och ta utgången i tågets färdrikning. Det tar ungefär tre minuter att promenera till min klinik som ligger på Kastrupgatn 1.
              </Typography>
              {/* <TextField
                noBorder
                placeholder="Your email"
                variant="standard"
                sx={{ width: '100%', mt: 3, mb: 2 }}
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                sx={{ width: '100%' }}
              >
                Keep me updated
              </Button> */}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { md: 'block', xs: 'none' }, position: 'relative' }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: -67,
              left: -67,
              right: 0,
              bottom: 0,
              width: '100%',
              background: 'url(/static/themes/onepirate/productCTAImageDots.png)',
            }}
          />
          <Box
            component="img"
            src={Images.LocationMap}
            alt="call to action"
            sx={{
              position: 'absolute',
              top: -28,
              left: -28,
              right: 0,
              bottom: 0,
              width: '100%',
              maxWidth: 600,
            }}
          />
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        closeFunc={handleClose}
        message="We will send you our best offers, once a week."
      />
    </Container>
  );
}

export default BasicContactInfo;