const Images = {
    ProductCurvyLines: require('./productCurvyLines.png'),
    Product1: require('./productHowItWorks1.svg').default,
    Product2: require('./productHowItWorks2.svg').default,
    Product3: require('./productHowItWorks3.svg').default,
    Lihua: require('./lihua.jpg'),
    LocationMap: require('./map2.jpg'),
    BookingAkupunktur: require('./Booking-akupunktur.jpg'),
    BackgroundImage: require('./ease-ny-spa.jpg'),
    Konsultation: require('./Konsultation.jpg'),
    MassageHomePage: require('./5.jpg'),
    BookingAkupunktur2: require('./Booking-akupunktur2.jpg'),
    NeckMassage: require('./neckmassage.jpg'),
    BackMassage: require('./3.jpg'),
    BodyMassage: require('./5.jpg'),
    BannerAcupuncture: require('./banner-acupuncture.jpg'),
    Cuppling: require('./cuppling2.jpg'),
    Cuppling1: require('./cupping1.jpg'),
    Self_Created_img: require('./TiBi_Acupuncture_on_a_beautiful_womans_back_a_realistic_scene_o_57c3ace6-6202-41ba-a441-87abd9799e07.png')
};

export default Images;