import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../Components/Typography';
import '../CSS/Selfintroduction.css'
import Images from '../Images/index';

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 1
};


function SelfIntroduction() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}
    >
      <Container sx={{ mt: 4, mb: 5, display: 'flex', position: 'relative' }}>
        <Box
          component="img"
          src={Images.ProductCurvyLines}
          alt="curvy lines"
          sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={Images.Lihua}
                alt="suitcase"
                sx={{ 
                  height: 442, 
                  width: 320, 
                  marginTop: 7,
                  zIndex: 1}}
              />
            </Box>
          </Grid>
          <Grid item xs={24} md={8}>
            <Box sx={item}>
              {/* <Box
                component="img"
                src={Images.Product2}
                alt="graph"
                sx={{ height: 55 }}
              /> */}
              <Typography variant="h6" sx={{ my: 0, fontSize: 26 }} style={{lineHeight: "24px", marginBottom: '16px'}}>
                Min berättelse – och hur jag kan hjälpa dig
              </Typography>
              <Typography className='introduction' variant="h5" display="block" style={{lineHeight: "24px", marginBottom: '16px'}}>
                {
                  'Hej och välkommen! Jag heter Lihua Guo. Jag föddes i staden Mudanjiang i Kina där jag i mycket tidig ålder kom i kontakt med den kinesiska läkekonsten. Min farfar var nämligen en erfaren och ganska berömd utövare av kinesisk medicin i staden. När jag besökte honom som litet barn kunde jag se hur han dagligen hjälpte människor att bli friskare med hjälp av naturläkemedel och akupunktur. Sedan dess har jag alltid varit intresserad av att hjälpa andra människor att må bättre.'
                }
              </Typography>
              <Typography className='introduction' variant="h5" display="block" style={{lineHeight: "24px", marginBottom: '16px'}}>  
                {
                'Jag är utbildad i kinesisk akupunktur i Kina. Jag har även gått en utbildning hos den i Kina mycket välkände professor Guo Yong Sheng, som har över 30 års erfarenhet inom traditionell kinesisk medicin.'
                }
              </Typography>
              <Typography className='introduction' variant="h5" display="block" style={{lineHeight: "24px", marginBottom: '16px'}}>
                {'Jag är inte på långa vägar lika erfaren som min farfar eller professor Guo Yong Sheng. Jag har inte tjugo eller trettio år av erfarenhet och jag kallar mig inte för ”doktor”. Men jag har utfört tusentals behandlingar här i Sverige och hjälpt hundratals människor att må bättre. Mina besökare är nästan alltid nöjda och om du är som dom flesta som besöker mig kommer du att lämna min klinik kännande dig mer frisk, rofylld och avslappnad.'}
              </Typography>
              <Typography className='introduction' variant="h5" display="block" style={{lineHeight: "24px", marginBottom: '16px'}}>
                {'Min svenska är inte perfekt. Svenska är väldigt annorlunda från kinesiska (Mandarin). Jag kom till Sverige år 2004 och jag försöker hela tiden lära mig mer och mer. Jag brukar förstå det mesta – och det viktigaste – men ibland kan det finnas några ord som jag ännu inte förstår. (Om du märker att jag inte riktigt förstår någonting får du gärna förklara det på ett annorlunda sätt. Tack!)'}
              </Typography>
         
            </Box>
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={Images.Product3}
                alt="clock"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Exclusive rates
              </Typography>
              <Typography variant="h5">
                {'By registering, you will access specially negotiated rates '}
                {'that you will not find anywhere else.'}
              </Typography>
            </Box>
          </Grid> */}
        </Grid>
      </Container>
    </Box>
  );
}

export default SelfIntroduction;