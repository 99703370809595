import BookingInfo from "../Views/BookingInfo";

function Booking() {
    return (
      <>
        <BookingInfo />
      </>
    );
  }

  export default Booking;
  